\:root
  --tint-gray:#424f68
  --tint-gray-fade:#B6BBCA

/* Inter
@font-face
  font-family: Inter
  src: url(./font/Inter/Inter-Light.ttf)
  font-weight: 300

@font-face
  font-family: Inter
  src: url(./font/Inter/Inter-Regular.ttf)
  font-weight: 400

@font-face
  font-family: Inter
  src: url(./font/Inter/Inter-Medium.ttf)
  font-weight: 500

@font-face
  font-family: Inter
  src: url(./font/Inter/Inter-SemiBold.ttf)
  font-weight: 600

@font-face
  font-family: Inter
  src: url(./font/Inter/Inter-Bold.ttf)
  font-weight: 700

/* Playfair

@font-face
  font-family: Playfair
  src: url(./font/Playfair/PlayfairDisplay-Regular.ttf)
  font-weight: 400

@font-face
  font-family: Playfair
  src: url(./font/Playfair/PlayfairDisplay-Medium.ttf)
  font-weight: 500

@font-face
  font-family: Playfair
  src: url(./font/Playfair/PlayfairDisplay-SemiBold.ttf)
  font-weight: 600

@font-face
  font-family: Playfair
  src: url(./font/Playfair/PlayfairDisplay-Bold.ttf)
  font-weight: 700

@font-face
  font-family: Playfair
  src: url(./font/Playfair/PlayfairDisplay-ExtraBold.ttf)
  font-weight: 800

@font-face
  font-family: Playfair
  src: url(./font/Playfair/PlayfairDisplay-Black.ttf)
  font-weight: 900

body
  overflow-x: hidden

a
  color: inherit
  text-decoration: none

*
  box-sizing: border-box

input:-webkit-autofill
  caret-color: #000

  &:hover, &:focus, &:active
    caret-color: #000

*:focus, button, input, span
  box-shadow: none !important
  outline: none !important

#chatbot-chat
  z-index: 500 !important

.block-scroll
  scrollbar-width: none

  &::-webkit-scrollbar
    display: none

@media (hover: none) and (pointer: coarse)
  .block-scroll
    width: 100vw

.booking-calendar.react-calendar--doubleView
  border: none

.custom-transform
  opacity: 1 !important
  transform: none !important
  transition: all 300ms ease-in-out
  overflow: hidden
  padding: 0 !important
  border: 1px solid var(--tint-gray)
  border-top: none
  max-height: 0
  border-bottom: none
  will-change: transform

.open-dropdown
  max-height: 500px
  border-bottom: 1px solid var(--tint-gray)

.chakra-modal__content-container
  height: 100% !important

textarea:focus, input:focus, button:focus
  box-shadow: none !important
  outline: none !important

.my-booking-carousel
  .alice-carousel__prev-btn, .alice-carousel__next-btn
    position: absolute
    width: 30px
    top: calc(50% - 33px)
    cursor: pointer

  .alice-carousel__prev-btn svg, .alice-carousel__next-btn svg
    color: #fff
    margin-top: 12px

  .alice-carousel__prev-btn
    left: -20px

  .alice-carousel__next-btn
    right: 0

.find-my-booking-carousel
  .alice-carousel__prev-btn, .alice-carousel__next-btn
    position: absolute
    width: 30px
    top: 25px

  .alice-carousel__prev-btn svg, .alice-carousel__next-btn svg
    color: #fff
    margin-top: 12px

  .alice-carousel__prev-btn
    left: -20px

  .alice-carousel__next-btn
    right: 0

#ssn
  background-color: #fff

.chakra-checkbox
  align-items: start !important

.disabled
  pointer-events: none
  opacity: 0.4

.full-center
  position: fixed
  top: 0
  left: 0
  background-color: #fff
  display: grid
  place-items: center
  width: 100vw
  height: 100dvh
  height: 100vh
  z-index: 900

.init-spinner
  width: 100px
  height: 100px
  border: 5px solid #000
  border-bottom-color: transparent !important
  border-right-color: transparent !important
  border-radius: 50%
  display: inline-block
  box-sizing: border-box
  animation: rotation 0.5s linear infinite

@keyframes rotation
  0%
    transform: rotate(0deg)

  100%
    transform: rotate(360deg)

@media screen and (min-width: 600px)
  .btn
    width: auto

@media screen and (max-width: 850px)
  .general-calendar
    margin-top: 30px

  #chatbot-chat .bubble
    margin-bottom: 40px !important

@mixin floating-label
  top: -10px
  left: 5px
  font-size: 10pt
  padding: 0 5px
  color: green
@mixin normal-label
  top: 8px
  left: 5px
  font-size: 11pt
  color: #414a4f
.custom-input
  font-family: "Roboto", Helvetica, Arial, sans-serif !important
  margin: 10px 0
  position: relative
  .arrow-icon
    pointer-events: none
    position: absolute
    right: 10px
    top: 4px
    color: green
  label
    position: absolute
    transition: all 200ms ease-in-out
    background-color: #fff
    pointer-events: none
    @include floating-label()
  select
    all: unset
  input,
  select,
  textarea
    transition: all 200ms ease-in-out
    border: 1px solid green
    border-radius: 5px
    padding: 7px 13px 5px
    font-size: 11pt
    width: 100%
    box-sizing: border-box
    &::placeholder
      color: #b0b0b0
      opacity: 0
      transition: opacity 200ms ease-in-out
    &:placeholder-shown
      + label
        @include normal-label()
      border-color: gray
    &:focus
      outline: none
      border-color: green
      + label
        @include floating-label()
      &::placeholder
        opacity: 1
  input[type="file"]::file-selector-button
    display: none
  select
    text-align: left
    color: #000
  textarea
    resize: unset
  .error-msg
    color: red
    font-size: 11pt
    margin-top: 5px
  &.error-input
    input,
    textarea,
    select
      border-color: red !important
    label,
    textarea,
    select
      color: red !important
    .arrow-icon
      color: red